import React from "react";
import ClientModal from "./ClientModal";

const ClientBanner = () => {
  return (
    <div className="clientbanner">
      <div className="clientbanner__bg">
        <div className="clientbanner__heading">/Kundcase</div>
        <div className="clientbanner__items">
          <div className="clientbanner__items--1">
            Skörstorps Bygdegårds-
            <br />
            och Hembygdsförening
            <ClientModal>
              <div className="modal-grid">
                <div className="modal-grid__1">
                  <div className="modal-grid__1--heading">
                    <h1>
                      Skörtorps Bygdegårds- <br />
                      och Hembygdsförening
                    </h1>
                  </div>
                </div>
                <div className="modal-grid__2">
                  <p>
                    <a
                      className="modal-grid__2--link"
                      href="https://www.hembygd.se/skorstorp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Skörstorps Bygdegårds & Hembygdsförening
                    </a>{" "}
                    ansökte om ett{" "}
                    <a
                      className="modal-grid__2--link"
                      href="http://leadernarheten.se/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Leader
                    </a>{" "}
                    bidrag och fick beviljat en summa för att digitalisera
                    bokning av bygdegårdar. Projektet inefattar inte bara{" "}
                    Skörtorps bygdegård utan poteniellt hela
                    Sveriges bygdegårdar.
                  </p>
                  <p>
                    För att kunna digitalisera deras bokningar och ta det från
                    papper & penna till webben bygger Klarr en webbplats med
                    integrerat bokningssystem. Målet är att det ska vara enkelt
                    för användaren att kunna boka och även enkelt för
                    föreningarna att administrera bokningarna.
                  </p>
                  <p>
                    I slutändan kommer webbplatsen fungera som en portal där
                    fler föreningar kan ansluta sig.
                  </p>
                </div>
                <div className="modal-grid__3"></div>
                <div className="modal-grid__4"></div>
                <div className="modal-grid__5"></div>
              </div>
            </ClientModal>
          </div>
        </div>

        <div className="clientbanner__items">
          <div className="clientbanner__items--2">
            Sundsgårdens
            <br />
            Folkhögskola
            <ClientModal>
              <div className="modal-grid">
                <div className="modal-grid__6">
                  <div className="modal-grid__6--heading">
                    <h1>Sundsgårdens Folkhögskola</h1>
                  </div>
                </div>
                <div className="modal-grid__7">
                  <p>
                    I höst startade{" "}
                    <a
                      className="modal-grid__7--link"
                      href="https://sundsgarden.se/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sundsgårdens Folkhögskola
                    </a>{" "}
                    en helt ny utbildning som heter "IT - som framtidsyrke". Utbildningen riktar sig
                    till dig som är nyfiken på webbutveckling och programmering.
                  </p>
                  <p>
                    Klarr har fått den stora äran att utbilda i Javascript under
                    utbildningen! I December 2020 startar kursen och det kommer
                    att bli två intensiva månader. Ett spännande och roligt
                    uppdrag där Klarr får möjligheten att inspirera och utveckla
                    andra människor.
                  </p>
                  <p>
                    Läs mer om utbildningen på{" "}
                    <a
                      className="modal-grid__7--link"
                      href="https://sundsgarden.se/utbildningar-kurser/langa-kurser/yrkesutbildningar/it-som-framtidsyrke/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sundsgårdens webbsida
                    </a>
                    .
                  </p>
                </div>
                <div className="modal-grid__8"></div>
                <div className="modal-grid__9"></div>
              </div>
            </ClientModal>
          </div>
        </div>

        <div className="clientbanner__items">
          <div className="clientbanner__items--3">
            Hemligt!?
            <ClientModal>
            <div className="modal-grid">
                <div className="modal-grid__10">
                  <div className="modal-grid__10--heading">
                    <h1>Hemligt uppdrag?</h1>
                  </div>
                </div>
                <div className="modal-grid__11">
                 <p>Klarr jobbar med ett mycket spännande och <span>hemligt</span> projekt. Såklart kommer det inte alltid att vara hemligt
                 och jag tillsammans med min kund ser verkligen fram emot att få släppa nyheten!</p>

                 <p>Klarr kan i nuläget inte avslöja några detaljer kring kundens projekt men tillsammans arbetar vi bland annat med att ta fram, identifiera och stärka 
                  kundens varumärke och affärsidé.
                  En logga har designats för att spegla kundens varumärke och varumärkets identitet. Projektet innefattar också en del digital produktdesign som 
                  Klarr tagit fram.
                 </p>
                 <p>Uppdraget kommer att fortsätta framöver och planen är att Klarr ska utveckla en webbshop åt kunden.</p>
                 <div className="modal-grid__11--tuned">Stay tuned!</div>
                </div>
                <div className="modal-grid__12"></div>
                <div className="modal-grid__13"></div>
                <div className="modal-grid__14"></div>
              </div>
            </ClientModal>
          </div>
        </div>

        <div className="clientbanner__items">
          <div className="clientbanner__items--4">
            Kennel Lakejs
            <ClientModal>
            <div className="modal-grid">
                <div className="modal-grid__15">
                  <div className="modal-grid__15--heading">
                    <h1>Kennel <br/> Lakej's</h1>
                  </div>
                </div>
                <div className="modal-grid__16">
                <p>Kennel Lakej's har fött upp dvärgschnuzer i många år med mycket god framgång. Den digitala biten hade
                  dock halkat efter rejält för Kennel Lakej's och kenneln saknade även en logga.
                </p>
                <p>Klarr har skapat en logga åt Kennel Lakej's och är mitt uppe i att utveckla en webbsida.</p> 
                
                <p>Önskemål fanns även att skapa 
                  ett mönster utifrån ett foto på en av avelshundarna. Klarr deisgnade självklart ett mönster (bilden längst ner till höger) och mönstret finns
                  nu tryckt på tallrikar och muggar.
                </p>
                </div>
                <div className="modal-grid__17"></div>
                <div className="modal-grid__18"></div>
              </div>
            </ClientModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientBanner;
