import React from "react";
import Logo from "../images/klarrclearbg.svg";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import BehanceIcon from "../icons/BehanceIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import { Link } from "react-scroll";

const Navbar = ({ sticky }) => {
  return (
    <nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
      <div className="navbar__logo--holder">
        {sticky ? <img src={Logo} alt="Logo" className="navbar__logo" /> : null}
        <ul className="navbar__socialmedia">
          <li className="navbar__socialmedia--links">
          <a
              href="https://www.facebook.com/klarrIT"
              target="_blank"
              rel="noopener noreferrer"
            >
            <FacebookIcon />
            </a>
          </li>
          <li className="navbar__socialmedia--links">
            <a
              href="http://www.instargram.com/klarr.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </a>
          </li>
          <li className="navbar__socialmedia--links">
          <a
              href="http://www.linkedin.com/company/klarr"
              target="_blank"
              rel="noopener noreferrer"
            >
            <LinkedInIcon />
            </a>
          </li>
          <li className="navbar__socialmedia--links">
          <a
              href="https://www.behance.net/klarr"
              target="_blank"
              rel="noopener noreferrer"
            >
            <BehanceIcon />
            </a>
          </li>
        </ul>
      </div>
    

      <div>
        <ul className="navbar__link">
          <li className="navbar__link--item">
            <Link
              activeClass="active"
              to="page-1"
              smooth={true}
              duration={500}
              className="btn-nav"
            >
              Start
            </Link>
          </li>
          <li className="navbar__link--item">
            <Link
              activeClass="active"
              to="page-2"
              smooth={true}
              duration={500}
              className="btn-nav"
            >
              Tjänster
            </Link>
          </li>
          <li className="navbar__link--item">
            <Link
              activeClass="active"
              to="page-3"
              smooth={true}
              duration={500}
              className="btn-nav"
            >
              Om Klarr
            </Link>
          </li>

          <li className="navbar__link--item">
            <Link
              activeClass="active"
              to="page-4"
              smooth={true}
              duration={500}
              className="btn-nav"
            >
              Kontakt
            </Link>
          </li>
          <li className="navbar__link--item">
            <Link
              activeClass="active"
              to="page-5"
              smooth={true}
              duration={500}
              className="btn-nav"
            >
              Kundcase
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
