import React from "react";

const LinkedInIcon = () => {
  return (
    <svg
    className="linkedin"
      width="103"
      height="100"
      viewBox="0 0 103 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
        className="linkedin__path"
          d="M98 46C98 68.6437 78.9721 87 55.5 87C32.0279 87 13 68.6437 13 46C13 23.3563 32.0279 5 55.5 5C78.9721 5 98 23.3563 98 46Z"
          fill="black"
        />
      </g>
      <path d="M45.4444 39.3046H37.6032V64.086H45.4444V39.3046Z" fill="white" />
      <path
        d="M41.5669 27C39.068 27 37 28.9791 37 31.4744C37 34.314 39.7574 36.5512 42.7732 35.7767C44.3243 35.4326 45.6168 34.1419 45.9615 32.6791C46.737 29.6674 44.4966 27 41.5669 27Z"
        fill="white"
      />
      <path
        d="M65.6077 38.7023C61.8163 38.7023 59.2313 40.7674 58.1111 42.6605H58.0249V39.3046H50.4422V64H58.2834V51.7814C58.2834 48.5977 58.8866 45.414 63.0227 45.414C67.0726 45.414 67.1587 49.114 67.1587 51.9535V64H75V50.4907C75 43.7791 73.5351 38.7023 65.6077 38.7023Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="103"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LinkedInIcon;
