import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import Logo from "../images/klarr.svg";
import ServicesGallery from "./ServicesGallery";
import ServicesCards from "./ServicesCards";
import AboutText from "./AboutText";
import AboutGallery from "./AboutGallery";
import Contact from "./Contact";
import ClientBanner from "./ClientBanner";
import Footer from "./Footer";

const Welcome = ({ element }) => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <main className="welcome">
      <section id="page-1" className="welcome__section">
        <div ref={element}>
          <img
            src={Logo}
            alt="Logo"
            className="welcome__logo"
           
          />
          <p className="welcome__text">
            Skräddarsydda appar, webbsidor och system där den bästa lösningen
            börjar och slutar med användaren.
          </p>
          <p className="welcome__text">
            Klarr skapar digitala kreationer designade för att matcha dina behov
            och drivkrafter.
          </p>
          <div className="welcome__button">
            <Link
            activeClass="active"
            to="page-3"
            smooth={true}
            duration={500}
            >
            Mer om Klarr
            </Link>
            </div>
          <div className="welcome__iconbox">
            <Link
              activeClass="active"
              to="page-2"
              smooth={true}
              duration={500}
              className="btn"
              id="btn-start"
            >
            <i
              className="fa fa-chevron-circle-down"
              aria-hidden="true"
              style={{ fontSize: "2.5rem" }}
            ></i>
            </Link>
          </div>
        </div>
        <div className="blob">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#FF8e27"
              d="M40.9,-53.8C55.1,-46,70.2,-36.9,75.3,-23.9C80.4,-10.9,75.4,6,66.1,17.4C56.8,28.8,43.2,34.8,31.5,42.7C19.7,50.7,9.9,60.6,-3.6,65.6C-17.1,70.5,-34.2,70.5,-47.4,63.1C-60.6,55.6,-70,40.7,-73.3,25.1C-76.5,9.5,-73.6,-6.7,-66.7,-19.8C-59.8,-32.8,-49,-42.6,-37.2,-51.3C-25.4,-59.9,-12.7,-67.4,0.3,-67.8C13.3,-68.3,26.7,-61.7,40.9,-53.8Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>

        <div className="blobone">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#6026ff"
              d="M34.5,-48.7C43.2,-33.9,47.5,-21.5,54.3,-6.2C61.1,9.2,70.3,27.5,66.2,41.8C62.2,56,45,66.2,27.5,70.3C10,74.4,-7.8,72.5,-22.2,65.3C-36.5,58.2,-47.3,45.9,-57.5,31.6C-67.7,17.3,-77.3,1,-76.1,-14.7C-74.8,-30.3,-62.7,-45.3,-48.2,-59C-33.7,-72.7,-16.8,-85.2,-2,-82.9C12.9,-80.6,25.9,-63.4,34.5,-48.7Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </section>

      <section id="page-2" className="services__section">
        <div className="services">
          <div className="services__heading">/ Tjänster</div>
          <ServicesGallery />
          <ServicesCards />
          <Link
              activeClass="active"
              to="page-3"
              smooth={true}
              duration={500}
              className="btn"
            >
          <i
            className="fa fa-chevron-circle-down"
            aria-hidden="true"
            style={{ fontSize: "2.5rem" }}
          ></i>
          </Link>
        </div>
        <div className="blobtwo">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#ffde17"
              d="M37.6,-63C47.9,-52,54.8,-40.1,62.9,-27.3C71,-14.5,80.3,-0.8,80.3,12.8C80.4,26.4,71.1,40,59.2,48.2C47.3,56.4,32.8,59.3,20.1,58.5C7.4,57.8,-3.5,53.3,-16,51.2C-28.6,49.1,-42.8,49.2,-52.5,42.8C-62.2,36.4,-67.3,23.4,-72.7,8.4C-78.1,-6.7,-83.8,-23.7,-78.7,-36.5C-73.7,-49.2,-58,-57.5,-43.1,-66.4C-28.2,-75.3,-14.1,-84.8,-0.2,-84.4C13.7,-84.1,27.4,-74,37.6,-63Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </section>

      <section id="page-3" className="about__section">
        <div className="about">
          <div className="about__heading">/ Om Klarr</div>
          <AboutText />
          <AboutGallery />
          <Link
              activeClass="active"
              to="page-4"
              smooth={true}
              duration={500}
              className="btn"
            >
          <i
            className="fa fa-chevron-circle-down"
            aria-hidden="true"
            style={{ fontSize: "2.5rem" }}
          ></i>
          </Link>
        </div>
        <div className="blobb">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#ff8e27"
              d="M62.1,-48.5C78.2,-29.3,87.3,-3.6,80.2,14.5C73,32.6,49.4,43.1,27.3,52C5.2,60.8,-15.5,68,-33.8,62.2C-52.1,56.5,-68.1,37.8,-71,18.1C-74,-1.6,-64,-22.3,-50,-41.1C-36,-59.8,-18,-76.6,2.5,-78.6C23,-80.5,46,-67.8,62.1,-48.5Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </section>

      <section id="page-4" className="contact__section">
        <div className="contact">
          <div className="contact__heading">/ Kontakt</div>
          <Contact />
          <Link
            activeClass="active"
            smooth={true}
            duration={500}
            className="btn"
            onClick={scrollToTop}
            to="page-1"
          >
          <i
            className="fa fa-chevron-circle-up"
            aria-hidden="true"
            style={{ fontSize: "2.5rem" }}
          ></i>
          </Link>
        </div>
        <div className="blobbb">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#ff007b"
              d="M56.3,-52.6C71.6,-41,81.7,-20.5,80.8,-0.9C79.9,18.8,68.1,37.5,52.8,47C37.5,56.5,18.8,56.6,1.5,55.1C-15.7,53.5,-31.3,50.3,-41.8,40.8C-52.3,31.3,-57.6,15.7,-56.2,1.4C-54.8,-12.9,-46.8,-25.8,-36.3,-37.4C-25.8,-49,-12.9,-59.3,3.8,-63.1C20.5,-66.9,41,-64.2,56.3,-52.6Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </section>

      <section id="page-5" className="footer__section">
        <div className="footer">
          <ClientBanner />
          <Footer />
        </div>
        <div className="blobbis">
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#6026FF"
              d="M35.5,-57.9C44.1,-49.7,47.8,-36.6,51.2,-24.7C54.6,-12.8,57.7,-2,56.6,8.4C55.6,18.9,50.4,29.1,43.5,38.5C36.6,47.9,27.8,56.4,17.5,59.4C7.1,62.4,-4.8,59.8,-15.8,55.7C-26.7,51.6,-36.7,46,-48,38.5C-59.4,31,-72.1,21.6,-74.1,10.4C-76.1,-0.8,-67.3,-13.7,-60.9,-27.8C-54.5,-41.9,-50.4,-57,-40.7,-64.7C-30.9,-72.3,-15.5,-72.4,-1,-70.8C13.5,-69.3,26.9,-66.1,35.5,-57.9Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
      </section>
    </main>
  );
};

export default Welcome;
