import React from "react";

const ServicesGallery = () => {
  return (
    <div className="s-gallery">
      <div className="s-gallery__1">
        <div className="s-gallery__1--info">
          <h1>Idé eller behov?</h1>
          <p>
            Oavsett vilken idé eller vilket behov du har kan vi tillsammans
            skapa din affärsidés framtid.
          </p>
        </div>
      </div>

      <div className="s-gallery__2">
        <div className="s-gallery__2--info">
          <h1>Kaffe eller digitalt möte?</h1>
          <p>
            Vill du ta en kopp kaffe och diskutera din idé? Eller trivs du
            bättre med ett digitalt möte?
          </p>
          <p> De bästa lösningarna bygger på bra relationer!</p>
        </div>
      </div>

      <div className="s-gallery__3">
        <div className="s-gallery__3--info">
          <h1>Målbild & strategi</h1>
          <p>
            Tillsammans bygger vi strategier och tydliggör dina utmaningar och
            möjligheter för att kunna nå dina affärsmål.
          </p>
        </div>
      </div>

      <div className="s-gallery__4">
        <div className="s-gallery__4--info">
          <h1>Branding </h1>
          <p>
            Hur vill du att ditt varumärke ska kommunicera och till vem? Med ett
            tydligt budskap till rätt målgrupp och i rätt kanaler har ditt
            varumärke mycket att vinna.
          </p>
        </div>
      </div>

      <div className="s-gallery__5">
        <div className="s-gallery__5--info">
          <h1>Webb & app</h1>
          <p>
            Klarr fokuserar på att utveckla din digitala idé så enkelt så
            möjligt men med robust och skalbar teknik. Klarr kan hjälpa dig med
            det lilla eller stå vid din sida under ett helt projekt.
          </p>
        </div>
      </div>

      <div className="s-gallery__6">
        <div className="s-gallery__6--info">
          <h1>I mål</h1>
          <p>
            Även om vi tillsammans nått dina mål och visioner finns Klarr vid
            din sida när din produkt eller tjänst lanseras. Vid slutet finns en
            lyckad affär och en ny relation!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesGallery;

/**
 *
 */
