import React from "react";

const FacebookIcon = () => {
  return (
    <svg
    className="facebook"
      width="103"
      height="100"
      viewBox="0 0 103 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
        className="facebook__path"
          d="M98 46C98 68.6437 78.9721 87 55.5 87C32.0279 87 13 68.6437 13 46C13 23.3563 32.0279 5 55.5 5C78.9721 5 98 23.3563 98 46Z"
          fill="#000000"
        />
      </g>
      <path
        d="M50.1 46V65.2C50.1 65.5 50.3 65.7 50.6 65.7H57.8C58.1 65.7 58.3 65.5 58.3 65.2V45.7H63.5C63.8 45.7 64 45.5 64 45.2L64.5 39.3C64.5 39 64.3 38.8 64 38.8H58.3V34.6C58.3 33.6 59.1 32.8 60.1 32.8H64.1C64.4 32.8 64.6 32.6 64.6 32.3V26.5C64.6 26.2 64.4 26 64.1 26H57.3C53.3 26 50 29.2 50 33.1V38.8H46.5C46.2 38.8 46 39 46 39.3V45.2C46 45.5 46.2 45.7 46.5 45.7H50.1V46Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="103"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
