import React from "react";
import Logo from "../images/klarrwhite.svg";

const Footer = () => {
  return (
    <div className="footernav">
      <div className="footernav__info">
        <div className="footernav__logo">
          <img src={Logo} alt="Logo" />
        </div>
        <p>
          Organisationsnummer: <br />
          8509214865
          <br />
          Stora Torget 7
          <br />
          521 42 Falköping
          <br />
          Klarr innehar F-skatt sedel.
        

        </p>
        <div className="footernav__info--copyright"> © Klarr Utvecklingsbyrå 2020</div>
      </div>

      <div className="footernav__adress">
       <div className="footernav__adress--image">
          
       </div>
      </div>
    </div>
  );
};

export default Footer;
