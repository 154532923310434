import React from "react";

const BehanceIcon = () => {
  return (
    <svg
    className="behance"
      width="103"
      height="100"
      viewBox="0 0 103 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
        className="behance__path"
          d="M98 46C98 68.6437 78.9721 87 55.5 87C32.0279 87 13 68.6437 13 46C13 23.3563 32.0279 5 55.5 5C78.9721 5 98 23.3563 98 46Z"
          fill="black"
        />
      </g>
      <path d="M74.3757 33.6431H61.963V36.5184H74.3757V33.6431Z" fill="white" />
      <path
        d="M55.3104 47.6091C54.5802 46.4589 53.4444 45.6374 51.9841 45.1445C52.9577 44.6516 53.6878 44.1586 54.1746 43.5014C55.067 42.4334 55.4727 41.0368 55.4727 39.3116C55.4727 37.6686 55.067 36.1898 54.1746 34.9575C52.7954 33.068 50.2804 32.0822 46.7919 32H33V60.2606H45.8995C47.3598 60.2606 48.739 60.0963 49.9559 59.8499C51.1728 59.6034 52.3086 59.1105 53.2011 58.4533C54.0123 57.8782 54.6614 57.1388 55.2293 56.2351C56.0406 54.9207 56.5273 53.4419 56.5273 51.7167C56.4462 50.0737 56.0406 48.7592 55.3104 47.6091ZM38.679 36.9292H44.9259C46.3051 36.9292 47.4409 37.0935 48.3333 37.3399C49.388 37.7507 49.8748 38.6544 49.8748 39.9688C49.8748 41.119 49.4691 42.0227 48.739 42.4334C47.9277 42.9263 46.9541 43.1728 45.7372 43.1728H38.679V36.9292ZM48.4956 54.8385C47.8466 55.1671 46.873 55.3314 45.575 55.3314H38.679V47.7734H45.7372C46.9541 47.7734 47.9277 47.9377 48.5767 48.2663C49.7937 48.8414 50.3616 49.8272 50.3616 51.3059C50.4427 53.0312 49.7937 54.2635 48.4956 54.8385Z"
        fill="white"
      />
      <path
        d="M78.8377 47.1983C78.5944 45.5552 78.0265 44.1586 77.134 42.8442C76.1605 41.4476 74.9436 40.3796 73.4832 39.7224C72.0229 39.0652 70.3192 38.7365 68.5344 38.7365C65.4515 38.7365 62.9365 39.7224 60.9894 41.6119C59.0423 43.5836 58.0688 46.2946 58.0688 49.9093C58.0688 53.7705 59.1235 56.5637 61.3139 58.2889C63.4233 60.0142 65.9383 60.8357 68.7778 60.8357C72.1852 60.8357 74.8624 59.8499 76.7284 57.796C77.9453 56.4816 78.5944 55.2493 78.7566 54.017H73.0776C72.7531 54.5921 72.3474 55.085 71.9418 55.4136C71.1305 56.0708 70.157 56.3994 68.8589 56.3994C67.642 56.3994 66.6684 56.153 65.776 55.5779C64.3968 54.7564 63.6667 53.2776 63.5855 51.1416H79C79 49.5807 79 48.1841 78.8377 47.1983ZM63.7478 47.7734C63.9101 46.3768 64.478 45.3088 65.2081 44.5694C66.0194 43.7479 67.0741 43.3371 68.5344 43.3371C69.8325 43.3371 70.9683 43.7479 71.7795 44.4873C72.672 45.2266 73.1587 46.3768 73.2399 47.7734H63.7478Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="103"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BehanceIcon;
