
import React, { useState } from 'react';
import { Modal } from "react-responsive-modal";

import "react-responsive-modal/styles.css";
import "../sass/base/_animations.scss";

const ClientModal = ({ title = "Info Modal", subtitle = "Info about services", children }) => {
    const [open, setOpen] = useState(false);

    
    
    return (
        <>
        <button className="modal-btn" onClick={() => setOpen(true)}></button>
        <Modal
            focusTrapped={false}
            open={open}
            onClose={() => setOpen(false)}
            center 
            styles={{
                modal: {
                  animation: `${
                    open ? 'customEnterAnimation' : 'customLeaveAnimation'
                  } 500ms`, transition: "ease 1s"
                },
              }}       
            classNames={{ modal: "infoModal" }}>
           
            
                <div className="modal-body">
                    {children}
                </div>
                
               
        </Modal>
        </>
    )
}

export default ClientModal;
