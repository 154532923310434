import React from "react";

const ServicesCards = () => {
  return (
    <div className="s-cards">
      <div className="s-cards__1">
        <div className="s-cards__1--icon" />
      </div>
      <div className="s-cards__5">
          Klarr fokuserar på att förstå din och se din verksamhet & affärsidé
          för att kunna leverera den bästa tekniska lösning som passar dig och
          bidrar till lönsamhet.
        </div>

      <div className="s-cards__2">
        <div className="s-cards__2--icon" />
      </div>
      <div className="s-cards__6">
          Genom tydligt gränssnitt, värdig design och robust teknik når vi
          tillsammans ditt digitala projekts mål.
        </div>

      <div className="s-cards__3">
        <div className="s-cards__3--icon" />
      </div>
      <div className="s-cards__7">
          Klarr utvecklar inte bara teknik utan också människor och
          organisationer. Ibland genom att jobba strategisk och visuellt med
          ditt varumärke och ibland genom att utbilda inom IT.
        </div>

      <div className="s-cards__4">
        <div className="s-cards__4--icon" />
      </div>
      <div className="s-cards__8">
          Kreativitet är att göra det svåra enkelt. En bra lösning börjar och
          slutar därför alltid med användaren.
        </div>
    </div>
  );
};

export default ServicesCards;
