import React, { Component } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-scroll";

import "react-toastify/dist/ReactToastify.min.css";

// Email validation
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

// Form validation
const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  // Validate form erros being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // Validate the form was filled out
  Object.values(rest).forEach((val) => {
    val === "" && (valid = false);
  });

  return valid;
};

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      formErrors: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
    };
  }

  toastifySuccess() {
    toast.success("Skickat!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
    });
  }

  toastifyFail() {
    toast.error("Var god fyll i alla fält!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback fail",
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (formValid(this.state)) {
      // Handle form validation success
      const { name, email, subject, message } = this.state;

      // Set template params
      let templateParams = {
        name: name,
        email: email,
        subject: subject,
        message: message,
      };

      emailjs.send(
        "gmail",
        "klarr_contact",
        templateParams,
        "user_r7fhXAnQHvxYsk0sZ6R0g"
      );

      console.log(` 
                    --SUBMITTING--
                    Name: ${name}
                    Email: ${email}
                    Subject: ${subject}
                    Message: ${message}`);

      this.toastifySuccess();
      this.resetForm();
    } else {
      // Handle form validation failure
      console.error("FORM INVALID - ERROR");
      this.toastifyFail();
    }
  };

  // Reset form
  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = { ...this.state.formErrors };

    switch (name) {
      case "name":
        formErrors.name =
          value.length < 1
            ? "Glöm inte att ange ditt namn eller ditt företags namn."
            : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Kontrollera att email adressen är giltlig.";
        break;
      case "subject":
        formErrors.subject =
          value.length < 1 ? "Fyll i rubriken är du snäll." : "";
        break;
      case "message":
        formErrors.message =
          value.length < 1 ? "Glöm inte att skriva ditt meddelande!" : "";
        break;
      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };

  render() {
    const { formErrors } = this.state;

    return (
      <div className="contactform">
        <div className="contactform__infobg">
          <div className="contactform__heading">Behöver du råd?</div>
          <div className="contactform__info">
            <p> Är du osäker på om er design speglar ert varumärke, eller 
            inte riktigt säker på vilken teknisk lösning som passar er?</p>
            <p>  Eller vill du ta en kopp kaffe och veta mer om <span> <Link
              activeClass="active"
              to="page-3" 
              smooth={true}
              duration={500}
              className="link-btn"
            >Klarr
            </Link>
            </span>?</p>

            <p>Skicka ett meddelande så hör jag av mig så snart som möjligt.</p>
          </div>
        </div>
        <form
          className="contactform__form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          <div className="contactform__item">
            <input
              type="text"
              name="name"
              value={this.state.name}
              className={`contactform__input ${
                formErrors.name.length > 0 ? "error" : null
              }`}
              onChange={this.handleChange}
              placeholder="Ditt namn"
              noValidate
            />
            {formErrors.name.length > 0 && (
              <span className="errorMessage">{formErrors.name}</span>
            )}
          </div>

          <div className="contactform__item">
            <input
              type="email"
              name="email"
              value={this.state.email}
              className={`contactform__input ${
                formErrors.email.length > 0 ? "error" : null
              }`}
              onChange={this.handleChange}
              placeholder="Din epost"
              noValidate
            />
            {formErrors.email.length > 0 && (
              <span className="errorMessage">{formErrors.email}</span>
            )}
          </div>

          <div className="contactform__item">
            <input
              type="subject"
              name="subject"
              value={this.state.subject}
              className={`contactform__input ${
                formErrors.subject.length > 0 ? "error" : null
              }`}
              onChange={this.handleChange}
              placeholder="Ämne"
              noValidate
            />
            {formErrors.subject.length > 0 && (
              <span className="errorMessage">{formErrors.subject}</span>
            )}
          </div>

          <div className="contactform__item">
            <textarea
              rows="6"
              name="message"
              value={this.state.message}
              className={`contactform__message ${
                formErrors.message.length > 0 ? "error" : null
              }`}
              onChange={this.handleChange}
              placeholder="Skriv ditt meddelande här"
              noValidate
            />
            {formErrors.message.length > 0 && (
              <span className="errorMessage">{formErrors.message}</span>
            )}
          </div>

          <button className="contactform__btn" type="submit">
            Skicka
          </button>
        </form>
        <ToastContainer />
      </div>
    );
  }
}

export default ContactForm;
