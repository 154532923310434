import React from "react";

import Logo from "../images/klarrclearbg.svg";

import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";
import LinkedInIcon from "../icons/LinkedInIcon";
import BehanceIcon from "../icons/BehanceIcon";

const AboutText = () => {
  return (
    <div className="a-text">
        <div className="a-text__selfie"/>
      <div className="a-text__info">
      <img className="a-text__logo" src={Logo} alt="Logo" />
        <p>"Att skapa och vara kreativ är min drivkraft och jag brinner för att
        utveckla teknik, människor och organisationer. Som fullstack utvecklare
        fokuserar jag på att leverera dynamiska och användarvänliga lösningar
        som också innebär affärsnytta för kunden.
        </p>
        <p style={{marginTop: "-1rem"}}>
        Min vision är att Klarr ska vara det omtänksamma och modiga företaget
        som gör skillnad och hjälper människor och organisationer att växa och
        utvecklas."
        </p>
      <div className="a-text__info--of">
        - Helena Johansson
        <br />
        Digital kreatör och grundare av Klarr.
      </div>
     

      <div className="a-text__links">
        Se mer av Klarrs kreationer
        <ul>
            <li className="a-text__links--item">
            <a
              href="https://www.facebook.com/klarrIT"
              target="_blank"
              rel="noopener noreferrer"
            >
            <FacebookIcon />
            </a>
            </li>
            <li className="a-text__links--item">
            <a
              href="http://www.instargram.com/klarr.se"
              target="_blank"
              rel="noopener noreferrer"
            >
            <InstagramIcon/>
            </a>
            </li>
            <li className="a-text__links--item">
            <a
              href="http://www.linkedin.com/company/klarr"
              target="_blank"
              rel="noopener noreferrer"
            >
            <LinkedInIcon />
            </a>
            </li>
            <li className="a-text__links--item">
            <a
              href="https://www.behance.net/klarr"
              target="_blank"
              rel="noopener noreferrer"
            >
            <BehanceIcon />
            </a>
            </li>
        </ul>
      </div>
      </div>
    </div>
  );
};

export default AboutText;
