import React from "react";

const InstagramIcon = () => {
  return (
    <svg
    className="instagram"
      width="103"
      height="100"
      viewBox="0 0 103 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
        className="instagram__path"
          d="M98 46C98 68.6437 78.9721 87 55.5 87C32.0279 87 13 68.6437 13 46C13 23.3563 32.0279 5 55.5 5C78.9721 5 98 23.3563 98 46Z"
          fill="black"
        />
      </g>
      <path
        d="M68.8571 35.6475C68.8571 36.9282 67.7647 37.9527 66.5042 37.9527C65.2437 37.9527 64.1513 36.9282 64.1513 35.6475C64.1513 34.3669 65.2437 33.3424 66.5042 33.3424C67.8487 33.3424 68.8571 34.3669 68.8571 35.6475Z"
        fill="white"
      />
      <path
        d="M56.084 36.3305C50.6218 36.3305 46.1681 40.7701 46.1681 46.1488C46.1681 51.5275 50.6218 55.8817 56.084 55.8817C61.5462 55.8817 66 51.4422 66 46.0634C66 40.6847 61.5462 36.3305 56.084 36.3305ZM56.084 52.3813C52.5546 52.3813 49.7815 49.5639 49.7815 46.0634C49.7815 42.563 52.6386 39.7456 56.084 39.7456C59.6134 39.7456 62.3865 42.563 62.3865 46.0634C62.3865 49.5639 59.5294 52.3813 56.084 52.3813Z"
        fill="white"
      />
      <path
        d="M63.9832 26H48.0168C41.3782 26 36 31.3787 36 37.9527V53.7473C36 60.3213 41.3782 65.7 48.0168 65.7H63.8992C70.5378 65.7 75.916 60.3213 75.916 53.7473V37.9527C76 31.3787 70.6219 26 63.9832 26ZM72.2185 53.7473C72.2185 58.2723 68.521 61.9434 63.8992 61.9434H48.0168C43.479 61.9434 39.6975 58.2723 39.6975 53.7473V37.9527C39.6975 33.4277 43.395 29.7566 48.0168 29.7566H63.8992C68.437 29.7566 72.2185 33.4277 72.2185 37.9527V53.7473Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="103"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
