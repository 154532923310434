import React from "react";
import ContactForm from "./ContactForm";

const Contact = () => {

    return (
        <div className="contact__grid">
            <div className="contact__grid--1">

            </div>

            <div className="contact__grid--2">

            </div>

            <div className="contact__grid--3">
            <ContactForm />
            </div>
        </div>
    )
}

export default Contact;