import React from "react";

import useSticky from "../hooks/useSticky.js";

import Navbar from "./Navbar";
import Welcome from "./Welcome";

const Main = () => {
    const { isSticky, element } = useSticky()

    return (
        <>
         <Navbar sticky={isSticky}/>
        <Welcome element={element} />
        </>
    )
}

export default Main;