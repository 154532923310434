import React from "react";

const AboutGallery = () => {
  return (
    <div className="a-gallery">
        <div className="a-gallery__grid">
        <div className="a-gallery__grid--1"></div>
      <div className="a-gallery__grid--2"></div>
      <div className="a-gallery__grid--3"></div>
      <div className="a-gallery__grid--4"></div>
      <div className="a-gallery__grid--5"></div>
        </div>
    </div>
  );
};

export default AboutGallery;
